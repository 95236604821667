import React from "react";

const CloseCircle = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00012 0.499939C3.41606 0.499939 0.500122 3.41588 0.500122 6.99994C0.500122 10.584 3.41606 13.4999 7.00012 13.4999C10.5842 13.4999 13.5001 10.584 13.5001 6.99994C13.5001 3.41588 10.5842 0.499939 7.00012 0.499939ZM9.35356 8.6465C9.40194 8.69247 9.44063 8.74766 9.46735 8.80881C9.49407 8.86997 9.50828 8.93585 9.50913 9.00259C9.50999 9.06932 9.49747 9.13555 9.47233 9.19737C9.44718 9.25919 9.40992 9.31535 9.36273 9.36254C9.31553 9.40973 9.25937 9.447 9.19755 9.47214C9.13573 9.49729 9.0695 9.5098 9.00277 9.50895C8.93604 9.50809 8.87015 9.49389 8.809 9.46717C8.74784 9.44045 8.69265 9.40176 8.64668 9.35337L7.00012 7.70712L5.35356 9.35337C5.25903 9.44318 5.13315 9.49251 5.00277 9.49084C4.87239 9.48918 4.74782 9.43664 4.65562 9.34444C4.56342 9.25224 4.51088 9.12767 4.50921 8.99729C4.50755 8.86691 4.55687 8.74103 4.64668 8.6465L6.29293 6.99994L4.64668 5.35338C4.55687 5.25884 4.50755 5.13297 4.50921 5.00259C4.51088 4.87221 4.56342 4.74764 4.65562 4.65544C4.74782 4.56324 4.87239 4.5107 5.00277 4.50903C5.13315 4.50736 5.25903 4.55669 5.35356 4.6465L7.00012 6.29275L8.64668 4.6465C8.74121 4.55669 8.86709 4.50736 8.99747 4.50903C9.12785 4.5107 9.25242 4.56324 9.34462 4.65544C9.43682 4.74764 9.48936 4.87221 9.49103 5.00259C9.4927 5.13297 9.44337 5.25884 9.35356 5.35338L7.70731 6.99994L9.35356 8.6465Z"
      fill="currentColor"
    />
  </svg>
);

export { CloseCircle };
