import React from "react";

const CheckmarkCircle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8.00012C14 4.68762 11.3125 2.00012 7.99997 2.00012C4.68747 2.00012 1.99997 4.68762 1.99997 8.00012C1.99997 11.3126 4.68747 14.0001 7.99997 14.0001C11.3125 14.0001 14 11.3126 14 8.00012Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M10.9999 5.5004L6.79994 10.5004L4.99994 8.5004"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckmarkCircle;
