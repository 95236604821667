import createReactComponent from '../createReactComponent';
export default createReactComponent('message-chatbot', 'IconMessageChatbot', [
  [
    'path',
    {
      d: 'M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9.5 9h.01', key: 'svg-1' }],
  ['path', { d: 'M14.5 9h.01', key: 'svg-2' }],
  ['path', { d: 'M9.5 13a3.5 3.5 0 0 0 5 0', key: 'svg-3' }],
]);
